import $ from 'jquery';
import { translations, t } from 'global/translations';

$(function () {
    $('.filter').on("click", function () {
        const $this = $(this);
        $this.toggleClass('filter');
        if ($this.hasClass('filter')) {
            $this.text(t(I18n.locale, 'more-filters'));
        } else {
            $this.text(t(I18n.locale, 'less-filters'));
        }
    });
});
